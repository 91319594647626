html{
    font-size: 16px;
}

*{
    font-family: 'Poiret One', cursive;
    font-weight: bolder;
    font-size: 1.4rem;
}

.srh{
    display: none;
}

.navv div{
    padding : 1%;
    margin: 1% 1%;
}

.usr {
    width : 50%;
}

.home{
    padding: 5%;

}

.lnk{
    color: white;
    background-color: black;
    padding: 15px;

}

.lnk:hover{
    background-color: grey;
}

.navv{
    background-image: url("./navborder.png");
    background-size: cover; /* Optional: Adjust how the image covers the element */
  background-repeat: no-repeat; /* Optional: Prevent image repetition */
  background-position: center; 
}

a{
    text-decoration: none;
    color: black;
}

img:hover{
    cursor: pointer;
}

.topic button{
    background-color: gray;
}
.addcmnt button{
    background-color: gray;
}

button:hover{
    cursor: pointer;
    background-color: white;
}

.mypst .lnk, .home .lnk, .sprt .lnk{
    padding: 4%;
}


.topic{
    font-family: 'Poiret One', cursive;
    font-size: 3.5rem;
    
    border-radius: 20px;
    padding: 1%;
}


.about{
    font-size: 1.5rem;
}

.logn, .sgnup{
    width: 30%;
}

.reactions img{
    width : 30px;
    height : 30px;
}

.cmntss{
    font-size: 2rem;
}

.cmntusr{
    font-size : xx-large;
}

.btnlgout{
    margin: auto;
}

@media (max-width : 1100px){
    *{
        font-size: 20px;
    }
}

@media (max-width : 1320px){
    .btnlgout{
        margin: 8px auto;
    }
}

@media (max-width : 900px){
    .cmntss{
        font-size: 1.2rem;
    }
    .cmntusr{
        font-size: 1rem;
    }
}

@media (max-width: 768px) {

    .reeel{
        width: 50px;
        height: 50px;
    }

    *, .usr div, .about{
        font-size: 14px;
    }

    .reactns img, .about img, .user img, .userid img{
        width: 15px;
        height: 15px;
    }

    .reactions img{
        width: 15px;
        height: 15px;
    }

    .sgnup, .logn{
        width: 70%;
    }
  }

  @media (max-width : 482px){
    .sprt{
        position: absolute;
        top : 3.3%;
        left: 25%;
    }
    .home{
        position: absolute;
        top : 3.2%;
        left: 50%;
    }

    .btnlgout{
        margin: 0;
    }
  }